import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {AuthService} from "@auth0/auth0-angular";

@Component({
    selector: 'app-member-menu',
    templateUrl: './member-menu.component.html',
    styleUrls: ['./member-menu.component.scss']
})
export class MemberMenuComponent implements OnInit {

    constructor(
        @Inject(DOCUMENT) public document: Document,
        public authService: AuthService
    ) {
    }

    ngOnInit(): void {
    }

}
