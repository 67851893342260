<button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="authService.user$ | async as user">
    <img matRipple class="nav-pic"
         [matTooltip]="user.name"
         [src]="user.picture"
         alt="Profile photo"/>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="profile">
        <mat-icon>person</mat-icon>
        Perfil
    </button>
<!--    <button mat-menu-item routerLink="my-finances">-->
<!--        <mat-icon>insert_chart_outlined</mat-icon>-->
<!--        Mis finanzas-->
<!--    </button>-->
    <button mat-menu-item (click)="authService.logout({ returnTo: document.location.origin })">
        <mat-icon>exit_to_app</mat-icon>
        Salir
    </button>
</mat-menu>
