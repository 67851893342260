import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {TemplatePortal} from '@angular/cdk/portal';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {MatSelectionListChange} from "@angular/material/list";

@Component({
    selector: 'app-table-column-context-menu',
    templateUrl: './table-column-context-menu.component.html',
    styleUrls: ['./table-column-context-menu.component.css']
})
export class TableColumnContextMenuComponent implements OnInit {
    @Input() contextMenuEvent: Observable<MouseEvent>;
    @Input() columnDefinitions: any[];
    @Output() onSelectionChange = new EventEmitter<MatSelectionListChange>();
    @ViewChild('contextMenu', {static: true}) contextMenu: TemplateRef<any>;

    sub: Subscription;
    overlayRef: OverlayRef | null;

    constructor(
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {
    }

    ngOnInit() {
        this.contextMenuEvent.subscribe(e => this.open(e));
    }

    open({x, y}: MouseEvent) {
        this.close();
        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo({x, y})
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                }
            ]);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close()
        });

        this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef));

        this.sub = fromEvent<MouseEvent>(document, 'click')
            .pipe(
                filter(event => {
                    const clickTarget = event.target as HTMLElement;
                    return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
                }),
                take(1)
            ).subscribe(() => this.close());
    }

    close() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }

    selectionChange(selectedOption: MatSelectionListChange) {
        this.onSelectionChange.emit(selectedOption);
    }
}
