<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer
                 class="sidenav docs-navbar-show-small"
                 fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isHandset$ | async) === false"
    >
        <mat-toolbar>
            <img
                    alt="Referidos"
                    src="/assets/images/bitremesas-logo-dark.png"
                    style="width: 100%;"
            />
        </mat-toolbar>

        <ul class="accordion">
            <li *ngFor="let item of menu">
                <a class="menu"
                   (click)="clicked(item)"
                   [routerLink]="item.link"
                   routerLinkActive="active-left-menu-item"
                >
                    <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                    <span>{{ item.label }}</span>
                    <mat-icon *ngIf="item.children && !item.expanded">expand_more</mat-icon>
                    <mat-icon *ngIf="item.children && item.expanded">expand_less</mat-icon>
                </a>

                <ul class="submenu" #submenu
                    [style.height.px]="item.expanded ? submenu.scrollHeight : 0">
                    <li *ngFor="let submenu of item.children">
                        <a
                                (click)="clicked(submenu)"
                                [routerLink]="submenu.link"
                                routerLinkActive="active-left-menu-item"
                        >
                            <mat-icon *ngIf="item.icon">{{submenu.icon}}</mat-icon>
                            <span>{{ submenu.label }}</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar color="primary" class="mat-elevation-z6">
            <button
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    *ngIf="isHandset$ | async"
                    (click)="drawer.toggle()"
                    class="docs-navbar-show-small m-r-5"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <img
                    alt="Referidos"
                    src="/assets/images/bitremesas-logo-light.png"
                    style="width:160px;margin-top: -8px; margin-right:5px;cursor:pointer;"
                    routerLink="/"
            />

            <ng-template ngFor let-item [ngForOf]="menu">
                <a *ngIf="item.link else menuBtn"
                   mat-button
                   class="docs-navbar-hide-small"
                   [routerLink]="item.link"
                   routerLinkActive="navbar-active-item"
                >
                    <span>{{item.label}}</span>
                </a>

                <ng-template #menuBtn>
                    <button mat-button
                            [matMenuTriggerFor]="subMenu"
                            class="docs-navbar-hide-small">
                        {{item.label}}
                    </button>
                    <mat-menu #subMenu="matMenu">
                        <a *ngFor="let subItem of item.children"
                           mat-menu-item
                           [routerLink]="subItem.link"
                           routerLinkActive="navbar-active-sub-item"
                        >
                            <span>{{subItem.label}}</span>
                        </a>
                    </mat-menu>
                </ng-template>
            </ng-template>

            <div class="flex-spacer"></div>
            <div fxLayout="row" fxLayoutGap="5px">
                <app-member-menu></app-member-menu>
            </div>
        </mat-toolbar>

        <div class="content">
            <router-outlet>
            </router-outlet>
        </div>

        <app-footer></app-footer>

    </mat-sidenav-content>
</mat-sidenav-container>
