import {Injectable} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {User} from "@auth0/auth0-spa-js";
import {Role} from "@app/features/user/interfaces";
import {environment} from "@environments/environment";


@Injectable({
    providedIn: 'root'
})
export class AuthUserService extends AuthService {

    extractRoles(user: User): Role[] {
        return user[`${environment.auth.audience}/roles`];
    }
}
