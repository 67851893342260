<footer fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayout.lt-sm="column"
        fxLayoutAlign.lt-sm="center center"
>
            <span>
                Copyright © 2022
                <a href="https://bitremesas.com" target="_blank" style="font-weight: 500;">BitRemesas</a>
            </span>
    <div>
        <a href="https://bitremesas.com/#about" target="_blank">Nosotros</a>
        <a href="https://bitremesas.com/faq" target="_blank">FAQ</a>
    </div>
</footer>
