const apiUri = 'https://api-ref-dev.sygeness.com/api';

export const environment = {
    production: false,
    auth: {
        domain: 'br-affiliates.us.auth0.com',
        clientId: 'RA8xWF8RMf7JLQo9L4GoRe7dsVbjd6zY',
        audience: 'https://referrals.bitremesas.com',
        redirectUri: window.location.origin,
        scope: ''
    },
    apiUri,
    httpInterceptor: {
        allowedList: [
            `${apiUri}/*`
        ]
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
