import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppMaterialModule} from "@app/app-material.module";
import {MemberMenuComponent} from './components/member-menu/member-menu.component';
import {MenuService} from "@app/shared/services/menu";
import {NotificationBellComponent} from './components/notification-bell/notification-bell.component';
import {FlexModule} from "@angular/flex-layout";
import {MasterLayoutComponent} from "@app/shared/layouts";
import {RouterModule} from "@angular/router";
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {TableColumnContextMenuComponent} from "@app/shared/components/table-column-context-menu/table-column-context-menu.component";
import {FooterComponent} from './components/footer/footer.component';
import {MessageDialogComponent} from './components/message-dialog/message-dialog.component';


@NgModule({
    declarations: [
        MasterLayoutComponent,
        MemberMenuComponent,
        NotificationBellComponent,
        ConfirmDialogComponent,
        TableColumnContextMenuComponent,
        FooterComponent,
        MessageDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AppMaterialModule,
        FlexModule,
    ],
    exports: [
        MemberMenuComponent,
        NotificationBellComponent,
        MasterLayoutComponent,
        ConfirmDialogComponent,
        MessageDialogComponent,
        TableColumnContextMenuComponent,
    ],
    providers: [
        MenuService
    ],
    entryComponents: [ConfirmDialogComponent, MessageDialogComponent]
})
export class SharedModule {
}
