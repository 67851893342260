import {MenuItemInterface} from "@app/shared/services/menu/menu-item.interface";
import {Role} from "@app/features/user/interfaces";

export const MENU: MenuItemInterface[] = [
    {
        label: 'Códigos',
        link: 'codes',
        roles: [Role.Promoter]
    },
    {
        label: 'Remesas',
        link: 'remittances',
        roles: [Role.Promoter]
    },
    {
        label: 'Usuarios',
        link: 'users',
        roles: [Role.Administrator]
    },
    {
        label: 'Retiros',
        link: 'withdrawals',
        roles: [Role.Administrator]
    },
    {
        label: 'Extracciones',
        link: 'extractions',
        roles: [Role.Promoter]
    }

];
