import {Inject, Injectable} from '@angular/core';
import {PromoterDetailedInterface, PromoterInterface} from "@app/features/user/interfaces";
import {StateService} from "@app/core/services/state/state.service";
import {PromoterApiService} from "@app/features/user/api";
import {DOCUMENT} from "@angular/common";
import {AuthService} from "@auth0/auth0-angular";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {MessageDialogComponent} from "@app/shared/components/message-dialog/message-dialog.component";
import {paginatorDefaultValues, PaginatorInterface} from "@app/shared/interfaces";

interface PromoterState {
    promoters: PromoterDetailedInterface[];
    paginator: PaginatorInterface;
    promoter: PromoterInterface;
    loading: boolean;
}

const initialState: PromoterState = {
    promoters: [],
    paginator: {...paginatorDefaultValues},
    promoter: null,
    loading: false
};

@Injectable({
    providedIn: 'root'
})
export class PromoterStateService extends StateService<PromoterState> {
    promoters$ = this.select(state => state.promoters);
    promoter$ = this.select(state => state.promoter);
    loading$ = this.select(state => state.loading);
    paginator$ = this.select(state => state.paginator);

    constructor(
        private apiService: PromoterApiService,
        @Inject(DOCUMENT) private document: Document,
        public authService: AuthService,
        private dialog: MatDialog
    ) {
        super(initialState);
    }

    loadPromoterInfo() {
        this.setState({loading: true});
        this.apiService.getPromoterInfo().subscribe((user) => {
            this.setState({promoter: user, loading: false});
        }, (error: HttpErrorResponse) => {
            if (error.status === 403) {
                const dialogRef = this.dialog.open(MessageDialogComponent, {
                    data: {
                        message: 'Debe activar su cuenta para poder hacer uso de la aplicación'
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.authService.logout({returnTo: this.document.location.origin});
                });
            }
        });
    }

    loadPromoters(page: number, limit: number = 50, filters?: any): void {
        this.setState({loading: true});
        this.apiService.getPromoters(page, limit, filters)
            .subscribe((response) => {
                this.setState({
                    promoters: response.body,
                    paginator: JSON.parse(response.headers.get('x-pagination')),
                    loading: false
                })
            }, () => this.setState({
                loading: false
            }));
    }

    saveUserProfile(data: any) {
        return this.apiService.saveUserProfile(data);
    }

    changePassword(data: any) {
        return this.apiService.changePassword(data);
    }
}
