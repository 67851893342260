export enum Role {
    Administrator = 'Administrator',
    Promoter = 'Promoter'
}

export interface PromoterInterface {
    id: string;
    balance: number;
    pendingAmount: number;
    minToWithdraw: number;
    commissionLimit: number;
}

export interface PromoterDetailedInterface extends PromoterInterface {
    auth0Id: string;
    email: string;
    name: string;
    totalEarned: number;
    totalWithdrawn: number;
}
