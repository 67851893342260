import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class SnackbarMessageService {
    private config: MatSnackBarConfig = new MatSnackBarConfig();

    constructor(private snackBar: MatSnackBar) {
        this.config.duration = 8000;
        this.config.verticalPosition = 'bottom';
        this.config.horizontalPosition = 'end';
    }

    showSuccess(message: string, action: string = 'X') {
        this.config.panelClass = 'green-snackbar';
        this.snackBar.open(message, action, this.config);
    }

    showWarning(message: string, action: string = 'X') {
        this.config.panelClass = 'orange-snackbar';
        this.snackBar.open(message, action, this.config);
    }

    showError(message: string, action: string = 'X') {
        this.config.panelClass = 'red-snackbar';
        this.snackBar.open(message, action, this.config);
    }

}
