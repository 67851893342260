import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SnackbarMessageService} from "@app/core/services/snackbar-message/snackbar-message.service";
import {DOCUMENT} from "@angular/common";
import {AuthService} from "@auth0/auth0-angular";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private messageService: SnackbarMessageService,
        @Inject(DOCUMENT) private document: Document,
        public authService: AuthService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(e => {
            if (e.status === 401) {
                // Auto logout if 401 response returned from api
                this.authService.logout({returnTo: this.document.location.origin});
            }
            if (e.error?.errors?.length) {
                for (const error of e.error.errors) {
                    this.messageService.showError(error);
                }
            } else {
                const error = e.error?.message || 'Error desconocido';
                this.messageService.showError(error);
            }

            return throwError(e);
        }));
    }
}
