import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
    public negativeButtonText: string;
    public positiveButtonText: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { message?: string, positiveButtonText?: string, negativeButtonText?: string }
    ) {
        this.negativeButtonText = data?.negativeButtonText || 'Cancelar';
        this.positiveButtonText = data?.positiveButtonText || 'Aceptar';
    }
}
