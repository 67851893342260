import {Injectable} from '@angular/core';
import {MenuItemInterface} from "@app/shared/services/menu/menu-item.interface";
import {MENU} from "@app/shared/services/menu/menu";
import {Role} from "@app/features/user/interfaces";


@Injectable()
export class MenuService {

    fetchMenu(roles: Role[]): MenuItemInterface[] {
        return MENU.filter(items => {
            return !items.roles
                ? true
                : items.roles.some(itemRole => {
                    return roles.some(userRole => userRole === itemRole)
                });
        });
    }
}
