export const paginatorDefaultValues: PaginatorInterface = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 25,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false
};

export interface PaginatorInterface {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    hasPrevious: boolean,
    hasNext: boolean
}
