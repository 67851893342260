export interface  MenuItemInterface{
  label: string;
  roles?: string[];
  link?: string;
  icon?: string;
  expanded?: boolean;
  children?: MenuItemLeaftInterface[];
}

export interface MenuItemLeaftInterface {
  label: string;
  link: string;
  icon?: string;
}
