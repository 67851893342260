import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MasterLayoutComponent} from "@app/shared/layouts";
import {AuthGuard} from "@auth0/auth0-angular";
import {ValidRoleGuard} from "@app/core/guards";
import {Role} from "@app/features/user/interfaces";

const routes: Routes = [
    {
        path: '',
        component: MasterLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'codes',
                loadChildren: () => import('./pages/codes/codes.module').then(m => m.CodesModule)
            },
            {
                path: 'remittances',
                loadChildren: () => import('./pages/remittances/remittances.module').then(m => m.RemittancesModule)
            },
            {
                path: 'extractions',
                loadChildren: () => import('./pages/extractions/extractions.module').then(m => m.ExtractionsModule),
                canActivate: [ValidRoleGuard],
                data: {
                    roles: [Role.Administrator, Role.Promoter]
                }
            },
            {
                path: 'users',
                loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
                canActivate: [ValidRoleGuard],
                data: {
                    roles: [Role.Administrator]
                }
            },
            {
                path: 'withdrawals',
                canActivate: [ValidRoleGuard],
                loadChildren: () => import('./pages/withdrawals/withdrawals.module').then(m => m.WithdrawalsModule),
                data: {
                    roles: [Role.Administrator, Role.Promoter]
                }
            },
            {
                path: 'profile',
                loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
            },
            // {
            //     path: 'my-finances',
            //     loadChildren: () => import('./pages/my-finances/my-finances.module').then(m => m.MyFinancesModule)
            // }
        ]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
