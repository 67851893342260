<ng-template #contextMenu>
    <mat-selection-list #shoes class="column-context-menu" (selectionChange)="selectionChange($event)">
        <mat-list-option *ngFor="let column of columnDefinitions"
                         [selected]="column.visible"
                         [value]="column.name"
                         [disabled]="shoes.selectedOptions.selected.length === 1 && column.visible"
        >
            {{column.label}}
        </mat-list-option>
    </mat-selection-list>
</ng-template>
