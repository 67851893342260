import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {
    public buttonText: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { message?: string, buttonText?: string }
    ) {
        this.buttonText = data?.buttonText || 'Aceptar';
    }

}
