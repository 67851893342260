import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, Subject} from 'rxjs';
import {map, shareReplay, takeUntil} from 'rxjs/operators';
import {MenuItemInterface} from "@app/shared/services/menu/menu-item.interface";
import {MenuService} from "@app/shared/services/menu";
import {Role} from "@app/features/user/interfaces";
import {AuthUserService} from "@app/features/user/services/auth-user.service";
import {PromoterStateService} from "@app/features/user/services";

@Component({
    selector: 'app-master-layout',
    templateUrl: './master-layout.component.html',
    styleUrls: ['./master-layout.component.scss']
})
export class MasterLayoutComponent implements OnInit, OnDestroy {
    menu: MenuItemInterface[] = [];
    destroyed$ = new Subject<void>();

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(
        private breakpointObserver: BreakpointObserver,
        private menuService: MenuService,
        public authService: AuthUserService,
        private promoterStateService: PromoterStateService
    ) {
    }

    ngOnInit(): void {
        this.authService.user$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(user => {
                if (user) {
                    const roles: Role[] = this.authService.extractRoles(user);
                    this.menu = this.menuService.fetchMenu(roles);

                    if(roles.some(role => role === Role.Promoter)) {
                        this.promoterStateService.loadPromoterInfo();
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    clicked(item: any) {
        if (item.children) {
            item.expanded = !item.expanded;
        }
    }

}
