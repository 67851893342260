import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Role} from "@app/features/user/interfaces";
import {environment} from "@environments/environment";
import {switchMap, take} from "rxjs/operators";
import {AuthService} from "@auth0/auth0-angular";

@Injectable({
    providedIn: 'root'
})
export class ValidRoleGuard implements CanActivate {

    private constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.authService.user$.pipe(
            take(1),
            switchMap((user) => {
                if (
                    route.data.roles
                    && route.data.roles.some((role: Role) => {
                        return user[`${environment.auth.audience}/roles`]
                            .some((userRole: Role) => userRole === role)
                    })
                ) {
                    return of(true);
                }

                this.router.navigate(['/']);
                return of(false);
            })
        );
    }

}
