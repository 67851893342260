import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {PromoterDetailedInterface, PromoterInterface} from "@app/features/user/interfaces";
import {environment} from "@environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PromoterApiService {

    constructor(
        private http: HttpClient
    ) {
    }

    getPromoterInfo(): Observable<PromoterInterface> {
        return this.http.get<PromoterInterface>(`${environment.apiUri}/promoter`);
    }

    getPromoters(page: number, limit: number, filters?: any): Observable<HttpResponse<PromoterDetailedInterface[]>> {
        let params = new HttpParams();

        if (page) {
            params = params.append('pageNumber', page);
        }
        if (limit) {
            params = params.append('pageSize', limit);
        }

        if (filters) {
            for (const key in filters) {
                if (filters.hasOwnProperty(key) && filters[key]) {
                    params = params.append(key, filters[key]);
                }
            }
        }

        return this.http.get<PromoterDetailedInterface[]>(
            `${environment.apiUri}/admin/promoters`,
            {
                observe: 'response',
                params
            }
        );
    }

    saveUserProfile(data: any): Observable<any> {
        return this.http.post(`${environment.apiUri}/promoter`, data);
    }

    changePassword(data: any): Observable<any> {
        return this.http.post(`${environment.apiUri}/promoter/change-password`, data);
    }
}
